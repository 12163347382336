import React, { useState, useEffect } from "react";
import "./header.css";
import { Container, Row, Col, Form } from "react-bootstrap";
import { FaMobileAlt, FaListAlt } from "react-icons/fa";
import { FiSearch, FiLogIn } from "react-icons/fi";
import { MdDeliveryDining } from "react-icons/md";
import { RiCustomerService2Fill } from "react-icons/ri";
import { HiMenuAlt1 } from "react-icons/hi";
import { AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import cartimage from "../../Assets/Images/Group 252 1 (1).svg";
import { useHistory } from "react-router-dom";
import profile from "../../Assets/Images/profile.svg";
import { IoBag } from "react-icons/io5";
import { connect } from "react-redux";

function Header({ cart, refreshitem }) {
  console.log(cart, "cart count");
  let history = useHistory();
      // let key1 = 2;
    let key1 = localStorage.getItem("apiid");
  const [cartCount, setCartCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [logtoken, setLogtoken] = useState("");
  const [cartQty, setCartQty] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const [cartSingleItems, setCartSingleItems] = useState([]);
  const[cartComboItems,setCartComboItems] =useState([])
  const [cartData1,setCartData1] = useState({})

  useEffect(() => {
    let count = 0;
    var total = 0;
    let comboPrice = 0;
    let singlePrice = 0;
    cart.forEach((item) => {
      const currentItem = Array.isArray(item.id) ? item.id[0] : item.id;
      if (currentItem.product_fetchiscombo == 1) {
        comboPrice += item.qty * currentItem.product_comboprice;
      } else if (currentItem.pb_salesrate) {
        singlePrice += item.qty * currentItem.pb_salesrate;
      }

      total = comboPrice + singlePrice;
      count += item.qty;
      console.log("cart", cart);
      setCartData1(currentItem)
    });
    

    setCartCount(count);
    setTotal(total);
  }, [cart, cartCount.toExponential]);


 console.log(cartData1,"current item")

  useEffect(() => {
    var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"));
    setLogtoken(logged);
    // if (logged == true) {
    getCartData();
    // }
  }, [refreshitem]);

  // const getCartData = () => {
  //   var token = JSON.parse(localStorage.getItem("token"))
  //   setLogtoken(token);
  //   fetch(global.url + "displayCartfrontend", {
  //     method: "POST",
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'APPKEY': 'erp6129',
  //       'Authorization': 'Bearer ' + token
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       if (responseJson.error == false) {
  //         console.log('cart data77', responseJson)
  //         setCartQty(responseJson.cart_count)
  //         setCartTotal(responseJson.total)
  //       }
  //       else {
  //         setCartQty(0)
  //         setCartTotal(0)
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }
  const getCartData = async () => {
    try {
      // Retrieve token from localStorage
      var token = JSON.parse(localStorage.getItem("token"));
      setLogtoken(token);

      // Make a POST request to fetch cart data
      const response = await fetch(global.url + "displayCartfrontend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          APPKEY: "erp6129",
          Authorization: "Bearer " + token,
        },
      });

      // Parse response as JSON
      const responseJson = await response.json();

      // Update cart quantity and total if there is no error in the response
      if (responseJson.error === false) {
        console.log("cart data77", responseJson);
        setCartSingleItems(responseJson.singleCart)
        setCartComboItems(responseJson.ComboCart)
        setCartQty(responseJson.cart_count);
        setCartTotal(responseJson.total);
      } else {
        // Reset cart quantity and total if there is an error
        setCartQty(0);
        setCartTotal(0);
      }
    } catch (error) {
      console.log(error); // Log any errors that occur during the request
    }
  };

  return (
    <div>
      {logtoken ? (
        <div  className="carthovermaindiv">
          <a onClick={() => history.push("/cart")} style={{ cursor: "pointer" }}>
            <div
              className="responsive-cart1"
              style={{ display: "flex", marginLeft: 20 }}
            >
              <div>
                <div
                  // alt="img" src={cartimage}
                  className="carticon"
                >
                  <IoBag className="cart-icon" color="white" />
                </div>
                {/* <p
                className="cartname"
                style={{
                  textAlign: "center",
                  fontFamily: "'lato', sans-serif",
                  fontWeight: 600,
                  fontSize: 17,
                  color: "#000",
                }}
              >
                Cart
              </p> */}
              </div>
              <div
                className="cartcount"
                style={{ marginLeft: "-29px", margintop: "2px" }}
              >
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    color: "#ffffff",
                    fontFamily: "'lato', sans-serif",
                    backgroundColor: "red",
                    padding: "5px 10px ",
                    borderRadius: "50px",
                  }}
                >
                  {cartQty}{" "}
                </p>
              </div>

              {/* <div className='searchbar' style={{ marginLeft: 14, marginTop: 23 }}>
              <p style={{ fontSize: 12, fontWeight: 500, marginBottom: 'unset', color: '#8f8f8f', fontFamily: "'lato', sans-serif" }}>{cartQty} </p>
              <p style={{ fontSize: 14, fontWeight: 'bold', color: '#00a085', fontFamily: "'lato', sans-serif" }}>QAR {cartTotal}</p>
            </div> */}
            </div>
          </a>
          <div className="carticonhover">
          {cartSingleItems.length>0 && cartSingleItems.map((item, index) => (
              <div className="carticonhoverdiv"  >
                <img  key={index} src={global.images +key1+ "/ecommerce_photos/" +item.image} className="carthoverimg" />
                <p className="carthovername">{item.name} <sup>({item.quantity})</sup></p>
              </div>
          ))}
          {cartComboItems.length>0 && cartComboItems.map((item, index) => (
              <div className="carticonhoverdiv"  key={index} >
                <img key={index} src={global.images +key1+ "/combo_photos/" +item.image} className="carthoverimg" />
                <p className="carthovername">{item.name} <sup>({item.quantity})</sup></p>
              </div>
          ))}
          </div>
        </div>
      ) : (
        <div className="carthovermaindiv">
        <a onClick={() => history.push("/cart")} style={{ cursor: "pointer" }}>
          <div
            className="responsive-cart1"
            style={{ display: "flex", marginLeft: 20 }}
          >
            <div>
              <div
                // alt="img" src={cartimage}
                className="carticon"
              >
                <IoBag className="cart-icon" color="white" />
              </div>
              {/* <p className='cartname' style={{textAlign:'center',fontFamily: "'lato', sans-serif",fontWeight:600,fontSize:17,color:'#000'}}>Cart</p> */}
            </div>
            <div className="cartcountdiv">
              {/* <p className='cartcount'  style={{ fontSize: 12, fontWeight: 600, color: '#ffffff', fontFamily: "'lato', sans-serif" ,backgroundColor:'red',padding:'5px 10px ',borderRadius:'50px'}}>{cart[0].qty} </p> */}
              <p
                className="cartcount"
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  color: "#ffffff",
                  fontFamily: "'lato', sans-serif",
                  backgroundColor: "red",
                  padding: "5px 10px ",
                  borderRadius: "50px",
                }}
              >
                {cartCount}{" "}
              </p>
            </div>

            {/* <div className='searchbar' style={{ marginLeft: 14, marginTop: 23 }}>
              <p style={{ fontSize: 12, fontWeight: 500, marginBottom: 'unset', color: '#8f8f8f', fontFamily: "'lato', sans-serif" }}>{cartQty} </p>
              <p style={{ fontSize: 14, fontWeight: 'bold', color: '#00a085', fontFamily: "'lato', sans-serif" }}>QAR {cartTotal}</p>
            </div> */}
          </div>
        </a>
        <div className="carticonhover">
          { cart.map((item, index) => (
             Array.isArray(item.id) ? (
              // console.log(item,"item 1221211233")
              <div  key={index} className="carticonhoverdiv"  >
                 <img  key={index} src={global.images +key1+ "/combo_photos/" + item.id[0].product_comboimage} className="carthoverimg" />
                <p className="carthovername">{item.id[0].product_name}<sup>({item.qty})</sup></p>
              </div>
             ):(
              
              // console.log(item,"item",item.id.images[0],"item else 1 1221211233")
              <div key={index} className="carticonhoverdiv"  >
                <img  src={global.images +key1+ "/ecommerce_photos/" +item.id.images[0].pei_image} className="carthoverimg" />
                <p className="carthovername">{item.id.product_name}<sup>({item.qty})</sup></p>
              </div>
             )
          ))}
          
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
    refreshitem: state.shop.refreshitem,
  };
};

export default connect(mapStateToProps)(Header);
