import "./Home.css";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Slider from "react-slick";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { addToCart, refresh } from "../../redux/Shopping/shopping-actions";
import { connect } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import img from "../../Assets/Images/noimages.png";
import { IoMdHeartEmpty } from "react-icons/io";
import { GoHeartFill } from "react-icons/go";
import { GoHeart } from "react-icons/go";
import { FaShoppingBag } from "react-icons/fa";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const data = [{}, {}, {}, {}, {}, {}, {}, {}];

function Products({ product, addToCart, cart, refresh }) {
  let history = useHistory();
  // let key1 = 2;
  let key1 = localStorage.getItem("apiid");

  console.log(product, "cat prd1252156")
  const LENGTH = 18;
  const DATA = [...Array(LENGTH).keys()];
  const LIMIT = 6;
  const [open, setOpen] = React.useState(false);
  const [logtoken, setLogtoken] = useState("");
  const [message, setMessage] = useState("");
  const [cartView, setCartview] = React.useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [cartList, setCartList] = useState([]);
  const [comboCartList, setComboCartList] = useState([]);

  const [cartQty, setCartQty] = useState("");
  const [cartTotal, setCartTotal] = useState("");


  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    // autoplay: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const closeviewCart = () => {
    setCartview(false);
  };

  const contHome = () => {
    history.push("/");
    setCartview(false);
  };

  useEffect(() => {
    let count = 0;
    var total = 0;
    cart.forEach((item) => {
      count += item.qty;
      total += item.qty * item.id.pb_salesrate;
    });
    setCartCount(count);
    setTotal(total);
  }, [cart, cartCount.toExponential]);

  const toClick = (product) => {
    // console.log("product", product)
    addToCart(product);
    setOpen(true);
    viewCart();
  };

  const viewCart = () => {
    setCartview(true);
  };

  const AddCart = (e,id) => {
    console.log("id", id);
    e.stopPropagation()
    var token = JSON.parse(localStorage.getItem("token"));
    var data = {
      productid: id,
      quantity: 1,
      iscombo: 0,
    };
    console.log("dataarr", data);
    fetch(global.url + "addCartfrontend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        viewCart();
        getCartData();
        if (responseJson.error == false) {
          setOpen(true);
          setMessage(responseJson.message);
          refresh("");
        } else {
          setOpen(true);
          setMessage(responseJson.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //   const getCartData = () => {
  //     var token = JSON.parse(localStorage.getItem("token"))
  //     fetch(global.url + "displayCartfrontend", {
  //         method: "POST",
  //          headers: {
  //              'Content-Type': 'application/json',
  //              'APPKEY': 'erp6129',
  //              'Authorization' : 'Bearer ' + token
  //          },
  //     })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log("responseJson cart123", responseJson)
  //       if(responseJson.error == false){
  //         setCartList(responseJson.singleCart);
  //         console.log(responseJson.singleCart,"single cart");
  //         setComboCartList(responseJson.ComboCart);
  //         console.log(responseJson.ComboCart,"combo cart");
  //         setCartQty(responseJson.cart_count)
  //         setCartTotal(responseJson.total)
  //       } else {
  //         setCartList([])
  //         setComboCartList([])
  //         setCartQty(0)
  //         setCartTotal(0)
  //       }
  //     })
  //     .catch((error)=>{
  //         console.log(error)
  //     })
  // }

  const getCartData = async () => {
    try {
      var token = JSON.parse(localStorage.getItem("token"));

      const response = await fetch(global.url + "displayCartfrontend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          APPKEY: "erp6129",
          Authorization: "Bearer " + token,
        },
      });

      const responseJson = await response.json();

      console.log("responseJson cart123", responseJson);

      if (responseJson.error === false) {
        setCartList(responseJson.singleCart);
        console.log(responseJson.singleCart, "single cart");
        setComboCartList(responseJson.ComboCart);
        console.log(responseJson.ComboCart, "combo cart");
        setCartQty(responseJson.cart_count);
        setCartTotal(responseJson.total);
      } else {
        setCartList([]);
        setComboCartList([]);
        setCartQty(0);
        setCartTotal(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const wishlistClick = (id, isFilled) => {
    console.log("wishlist clicking")
    var token = JSON.parse(localStorage.getItem("token"));

    var user = JSON.parse(localStorage.getItem("userdata"))
    if (!token) {
      setOpen(true)
      setMessage("please login to add to your wishlist")
      setTimeout(() => {
        history.push('/login')
      }, 2000);

      return

    }
    var data = {
      batch_id: id,
      mode: isFilled ? 'delete' : 'add',
      is_combo: 0,
    };
    if(token){
      data.user_id = user.user_id
    }

    fetch(global.url + "AddtowishlistOrDelete", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'APPKEY': 'erp6129',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          console.log(responseJson, "add to wishlist")
        } else {
          console.log(responseJson, "not in wish list")
        }
        console.log("order cancel response", responseJson);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [currentProduct, setCurrentProduct] = useState(product);

  const handleIconClick = (id, isFilled) => {
    console.log(id,"idsdsadasdasd")
    wishlistClick(id, isFilled);

    const updatedProduct = {
      ...currentProduct,
      wishlist: !currentProduct.wishlist
    };

    setCurrentProduct(updatedProduct);
  };

  useEffect(() => {
    var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"));
    setLogtoken(logged);
    getCartData();
  }, []);

  useEffect(() => {
    setMessage("Add To Cart");
  }, [cart]);

  const historyPushFunction = () => {
    history.push("/details/" + product.productid + "/" + product.batchid)
  }

  const handleMainIconClick = (e, id, isFilled) => {
    e.stopPropagation()
    handleIconClick(id, isFilled)
  }

  return (
    <div
      className="categoryimg"
      style={{
        marginTop: 10,
        fontWeight: 500,
        fontFamily: "'lato', sans-serif",
      }}
    >
      <div className="newarrival-img-border">
        <a
          onClick={() => historyPushFunction()}
          style={{ textDecoration: "none", cursor: "pointer" }}
        >
          <Slider {...settings}>
            {product.images.length == 0 ? (
              <div>
                <img alt="img" src={img} className="imagehome" />
                {currentProduct.wishlist == true ? (
                  <GoHeartFill
                    className='activehearticon'
                    style={{ cursor: 'pointer', color: 'red' }}
                    onClick={(e) => handleMainIconClick(e, product.batchid, true)}
                  />
                ) : (
                  <GoHeart
                    className='hearticon'
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => handleMainIconClick(e, product.batchid, false)}
                  />
                )}
              </div>
            ) : (
              product.images.map((products) => (
            
                  <div>
                    <img
                      alt="img"
                      src={
                        global.images + key1
                        +
                        "/ecommerce_photos/" +
                        products.pei_image
                      }
                      className="imagehome"
                    />
                    {currentProduct.wishlist == true ? (
                      <GoHeartFill
                        className='activehearticon'
                        style={{ cursor: 'pointer', color: 'red' }}
                        onClick={(e) => handleMainIconClick(e, product.batchid, true)}
                      />
                    ) : (
                      <GoHeart
                        className='hearticon'
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => handleMainIconClick(e, product.batchid, false)}
                      />
                    )}
                  </div>
                
              ))
            )}
          </Slider>

          <div
          // style={{marginTop: 10, position: 'relative'}}
          >
            <p className="product-name">{product.name}</p>
            <Row>
              <Col>
                <p className="price" style={{ marginBottom: "unset" }}>
                  {product.currency} {product.pb_salesrate}
                </p>
              </Col>
              {
                logtoken ?
                  <Col style={{ display: 'flex', justifyContent: 'end', fontSize: '20px' }}>  <FaShoppingBag style={{ height: '20px', width: '20px', cursor: 'pointer' }} onClick={(e) => AddCart(e,product.batchid)} /></Col>
                  :
                  // 
                  null
              }
              {/* <Col style={{ display: 'flex', justifyContent: 'end', fontSize: '20px' }}>
                {currentProduct.wishlist == true ? (
                  <IoMdHeartEmpty
                    style={{ cursor: 'pointer', color: 'red' }}
                    onClick={(e) => handleMainIconClick(e, product.batchid, true)}
                  />
                ) : (
                  <IoMdHeartEmpty
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => handleMainIconClick(e, product.batchid, false)}
                  />
                )}

              </Col> */}
            </Row>

            {/* <p style={{fontSize: 12}}><span style={{textDecorationLine: 'line-through', color: '#5c5c5c'}}>{product.currency} {product.pb_salesrate}</span></p> */}
            {/* <div style={{marginTop: 25}}>
                        {logtoken ?
                          <Button onClick={() => AddCart(product.pb_id)} variant="warning home-cartbtn" className="btn-warning">Add To Cart</Button>
                          :<Button onClick={() => toClick(product)} variant="warning home-cartbtn" className="btn-warning">Add To Cart</Button>}
                      </div> */}
          </div>
        </a>
      </div>
      {cartView ? (
        <div className="cart-content">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p style={{ fontWeight: 500, fontSize: 16, marginTop: 1 }}>
                Cart
              </p>
            </div>
            <a onClick={closeviewCart}>
              <AiOutlineClose color="#000" size={18} />
            </a>
          </div>
          {logtoken ? (
            <div className="innerdiv">
              {cartList.length === 0 && comboCartList.length === 0 ? (
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    textAlign: "center",
                    marginTop: 30,
                  }}
                >
                  No Cart Added
                </p>
              ) : (
                <div>
                  <div>
                    {cartList.map((item, index) => (
                      <div>
                        <Row>
                          <Col xs={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {item.image == null ? (
                                <img
                                  alt="img"
                                  src={img}
                                  style={{ width: 80, height: 80 }}
                                />
                              ) : (
                                <img
                                  alt="img"
                                  src={
                                    global.images + key1
                                    +
                                    "/ecommerce_photos/" +
                                    item.image
                                  }
                                  style={{ width: 80, height: 80 }}
                                />
                              )}
                            </div>
                          </Col>
                          <Col xs={9}>
                            <div>
                              <p
                                className="product-name"
                                style={{ fontSize: 11 }}
                              >
                                {item.name}
                              </p>
                              <p
                                style={{
                                  fontSize: 11,
                                  color: "#000",
                                  marginBottom: "unset",
                                  fontWeight: 500,
                                }}
                              >
                                {item.quantity} x {item.price} {item.currency}
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <div
                          style={{
                            backgroundColor: "#d3d3d3",
                            width: "100%",
                            height: 1,
                          }}
                        ></div>
                      </div>
                    ))}
                    {comboCartList.map((item, index) => (
                      <div>
                        <Row>
                          <Col xs={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {item.image == null ? (
                                <img
                                  alt="img"
                                  src={img}
                                  style={{ width: 80, height: 80 }}
                                />
                              ) : (
                                <img
                                  alt="img"
                                  src={
                                    global.images + key1
                                    +
                                    "/combo_photos/" +
                                    item.image
                                  }
                                  style={{ width: 80, height: 80 }}
                                />
                              )}
                            </div>
                          </Col>
                          <Col xs={9}>
                            <div>
                              <p
                                className="product-name"
                                style={{ fontSize: 11 }}
                              >
                                {item.name}
                              </p>
                              <p
                                style={{
                                  fontSize: 11,
                                  color: "#000",
                                  marginBottom: "unset",
                                  fontWeight: 500,
                                }}
                              >
                                {item.quantity} x {item.price} {item.currency}
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <div
                          style={{
                            backgroundColor: "#d3d3d3",
                            width: "100%",
                            height: 1,
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="innerdiv">
              {cart.length == 0 ? (
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    textAlign: "center",
                    marginTop: 30,
                  }}
                >
                  No Cart Added
                </p>
              ) : (
                <div>
                  <div>
                    {cart.map((item, index) => (
                      <div>
                        <Row>
                          <Col xs={3}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {item.id.images.length == 0 ? (
                                <img
                                  alt="img"
                                  src={img}
                                  style={{ width: 80, height: 80 }}
                                />
                              ) : (
                                <img
                                  alt="img"
                                  src={
                                    global.images + key1
                                    +
                                    "/ecommerce_photos/" +
                                    item.id.images[0].pei_image
                                  }
                                  style={{ width: 80, height: 80 }}
                                />
                              )}
                            </div>
                          </Col>
                          <Col xs={9}>
                            <div>
                              <p
                                className="product-name"
                                style={{ fontSize: 11 }}
                              >
                                {item.id.name}
                              </p>
                              <p
                                style={{
                                  fontSize: 11,
                                  color: "#000",
                                  marginBottom: "unset",
                                  fontWeight: 500,
                                }}
                              >
                                {item.qty} x {item.id.pb_salesrate}{" "}
                                {item.id.currency}
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <div
                          style={{
                            backgroundColor: "#d3d3d3",
                            width: "100%",
                            height: 1,
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {logtoken ? (
            <div style={{ marginTop: 20 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: 500, fontSize: 14 }}>Sub total</p>
                <p style={{ fontWeight: 500, fontSize: 14 }}>QAR {cartTotal}</p>
              </div>
              <Row>
                <Col xs={12} md={6}>
                  <Button
                    onClick={() => history.push("/checkout")}
                    variant="primary"
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                      width: "100%",
                      borderColor: "#00a085",
                      backgroundColor: "#00a085",
                      color: "#fff",
                      marginTop: 10,
                      boxShadow: "none",
                    }}
                  >
                    Checkout
                  </Button>
                </Col>
                <Col xs={12} md={6}>
                  <Button
                    onClick={() => contHome()}
                    variant="light"
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                      width: "100%",
                      borderColor: "#00a085",
                      backgroundColor: "none",
                      color: "#00a085",
                      marginTop: 10,
                      boxShadow: "none",
                    }}
                  >
                    Continue Shopping
                  </Button>
                </Col>
              </Row>
            </div>
          ) : (
            <div style={{ marginTop: 20 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: 500, fontSize: 14 }}>Sub total</p>
                <p style={{ fontWeight: 500, fontSize: 14 }}>QAR {total}</p>
              </div>
              <Row>
                <Col xs={6}>
                  <Button
                    onClick={() => history.push("/login")}
                    variant="primary"
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                      width: "100%",
                      borderColor: "#00a085",
                      backgroundColor: "#00a085",
                      color: "#fff",
                      marginTop: 10,
                      boxShadow: "none",
                    }}
                  >
                    Checkout
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    onClick={() => contHome()}
                    variant="light"
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                      width: "100%",
                      borderColor: "#00a085",
                      backgroundColor: "none",
                      color: "#00a085",
                      marginTop: 10,
                      boxShadow: "none",
                    }}
                  >
                    Continue Shopping
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </div>
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => dispatch(addToCart(item)),
    refresh: (item) => dispatch(refresh(item)),
  };
};

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
