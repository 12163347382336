import "./TopSelling.css";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import img from "../../Assets/Images/noimages.png";
import Slider from "react-slick";
import Products from "./sellingProducts";

function TopSelling(props) {
  let history = useHistory();

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    // autoplay: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
console.log(props.data ,"rprrprprprpr")
  useEffect(() => {
    console.log("props.data", props.data);
  }, []);

  return (
    <div>
      <Row>
        {props.data.map((product, index) => (
          <Col
            xs={6}
            md={4}
            lg={3}
            xl={2}
            style={{ marginTop: 10 }}
            className="spacereduce"
          >
            <Products key={product.id} product={product} />
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default TopSelling;
