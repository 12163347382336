import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function Mobcat() {
    const [subcatlist, setSubcatlist] = useState([]);
    const [subCatLists, setSubCatLists] = useState([]);
    const [loading, setLoading] = useState(false);
    let history = useHistory
    let key1 = localStorage.getItem("apiid");

    useEffect(() => {
        getSubcategorylist()
    }, [])
    const getSubcategorylist = () => {
        setLoading(true);
        // var userdata = JSON.parse(localStorage.getItem("user"))
        // var branch = JSON.parse(localStorage.getItem("branch"))
        // var company = JSON.parse(localStorage.getItem("company"))

        var data = {
            type: "admin",
            companyid: 1,
        };
        fetch(global.url + "viewSubcategory", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setLoading(false)
                if (responseJson.status == "false") {
                    setSubCatLists(responseJson.data);
                    // code used for avoiding repeating category names
                    const uniqueCategories = new Set();
                    const filteredData = responseJson.data.filter((subcategory) => {
                        const categoryName = subcategory.category_name;
                        if (!uniqueCategories.has(categoryName)) {
                            uniqueCategories.add(categoryName);
                            return true;
                        }
                        return false;
                    });
                    setSubcatlist(filteredData);
                    // console.log(filteredData, "filtered data");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <div>
            <Container>
            <Row>
                {
                    loading ? (
                        <Row>
                            <Col xs={6} md={6} lg={6} style={{ marginTop: 20 }}>
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Skeleton
                                            sx={{ width: 130, height: 120 }}
                                            animation="wave"
                                            variant="circular"
                                        />
                                    </div>
                                    <Skeleton variant="text" />
                                </div>
                            </Col>
                            <Col xs={6} md={6} lg={6} style={{ marginTop: 5 }}>
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Skeleton
                                            sx={{ width: 130, height: 120 }}
                                            animation="wave"
                                            variant="circular"
                                        />
                                    </div>
                                    <Skeleton variant="text" />
                                </div>
                            </Col>
                            <Col xs={6} md={6} lg={6} style={{ marginTop: 5 }}>
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Skeleton
                                            sx={{ width: 130, height: 120 }}
                                            animation="wave"
                                            variant="circular"
                                        />
                                    </div>
                                    <Skeleton variant="text" />
                                </div>
                            </Col>
                            <Col xs={6} md={6} lg={6} style={{ marginTop: 5 }}>
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Skeleton
                                            sx={{ width: 130, height: 120 }}
                                            animation="wave"
                                            variant="circular"
                                        />
                                    </div>
                                    <Skeleton variant="text" />
                                </div>
                            </Col>
                            <Col xs={6} md={6} lg={6} style={{ marginTop: 5 }}>
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Skeleton
                                            sx={{ width: 130, height: 120 }}
                                            animation="wave"
                                            variant="circular"
                                        />
                                    </div>
                                    <Skeleton variant="text" />
                                </div>
                            </Col>
                            <Col xs={6} md={6} lg={6} style={{ marginTop: 5 }}>
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Skeleton
                                            sx={{ width: 130, height: 120 }}
                                            animation="wave"
                                            variant="circular"
                                        />
                                    </div>
                                    <Skeleton variant="text" />
                                </div>
                            </Col>
                        </Row>
                    ) : Array.isArray(subcatlist) && subcatlist.length > 0 ? (
                        subcatlist.map((item) => (
                            item.category_name === null ? null :
                                <Col xs={6} md={3} lg={2} style={{ marginTop: 25 }}>
                                    <a
                                        onClick={() =>
                                            history.push("/category/" + item.subCategory_category)
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div>
                                            <div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                    className="cat-imgview"
                                                >
                                                    <img
                                                        alt="img"
                                                        src={
                                                            global.images + key1 + "/ecommerce_photos/" + item.category_image
                                                        }
                                                        style={{ width: "80%", height: "100%" }}
                                                    />
                                                </div>
                                                <p
                                                    style={{
                                                        textAlign: "center",
                                                        marginTop: 14,
                                                        fontSize: 18,
                                                        fontWeight: 600,
                                                        marginBottom: "unset",
                                                        fontFamily: "'lato', sans-serif",
                                                        textTransform: "capitalize",
                                                    }}
                                                >
                                                    {item.category_name}
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </Col>
                        ))
                    ) : null
                    //     (
                    //     // <p>No subcategories found</p>
                    // )
                }
            </Row>
            </Container>
        </div>
    )
}
export default Mobcat