// import React, {Component, useState, useEffect,useRef} from 'react';
// import { Route, Redirect } from 'react-router-dom';
// import Header from '../Header/header.js';
// import Footer from '../Footer/Footer';
// import Sidebar from './sidebar';
// import BottomBar from '../BottomBar/Bottombar.js' ;

// const DashboardLayout = ({children, ...rest}) => {
//   const [toggle, setToggle]  = useState(false)

//   const sidebarRef = useRef(null);

//   const handleCallback = () => {
//     setToggle(!toggle);
//   };

//   const handleClickOutside = (event) => {
//     console.log(" enmters ")
//     if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
//       console.log("outside click code ")
//       setToggle(false);
//     }
//   };

//   useEffect(() => {
//     if (toggle) {
//       console.log("if toggle")
//       document.addEventListener('mousedown', handleClickOutside);
//     } else {
//       console.log("else toggle")

//       document.removeEventListener('mousedown', handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [toggle]);


//     return(
//         <div>
//           <Sidebar ref={sidebarRef} toggle={toggle}/>
//             <Header parentCallback = {handleCallback}/>
//                 <div className={`layout ${toggle ? 'inactive' : ''}`}
//                 // style={{marginTop: '175px', marginBottom: 50}}
//                 >{children}</div>
//                 {toggle && <div className="overlay" />}
//             <Footer />
//             <BottomBar className="bottombar-main"/>
//         </div>
//     )
// };

// const CommonLayoutRoute = ({ component: Component, ...rest }) => {
//     return (
//       <Route
//         {...rest}
//         render={(props) => (
         
//           <DashboardLayout>
//             <Component {...props} />
//           </DashboardLayout> 
//         )}
//       />
//     );
//   };
// export default CommonLayoutRoute;




import React, {Component, useState, useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import Header from '../Header/header.js';
import Footer from '../Footer/Footer';
import Sidebar from './sidebar';
import BottomBar from '../BottomBar/Bottombar.js' ;

const DashboardLayout = ({children, ...rest}) => {
  const [toggle, setToggle]  = useState(false)

  const handleCallback = () => {
    setToggle(!toggle)
  }

    return(
        <div>
          <Sidebar toggle={toggle}/>
            <Header parentCallback = {handleCallback}/>
                <div className='layout' 
                // style={{marginTop: '175px', marginBottom: 50}}
                >{children}</div>
            <Footer />
            <BottomBar className="bottombar-main"/>
        </div>
    )
};

const CommonLayoutRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => (
         
          <DashboardLayout>
            <Component {...props} />
          </DashboardLayout> 
        )}
      />
    );
  };
export default CommonLayoutRoute;