import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import logo from '../../Assets/Images/snap qatar logo  1.png';
import { useHistory } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../Components/Loader/Loader';
import { authentication } from '../../firebase';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { connect } from "react-redux";
import {
    refresh
} from "../../redux/Shopping/shopping-actions";



//   firebase.auth().settings.appVerificationDisabledForTesting = true;
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Guest({ cart, refresh }) {
    console.log(cart,"the cart value in glog")
    let history = useHistory();
    const countryCode = '+974 ';
    // const countryCode = '+91';
    const vertical = "top"
    const horizontal = "center"
    const [mobile, setMobile] = useState('');
    const [otp, setOtp] = useState("");
    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrormessage] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [modal, setModal] = useState(false);

    // const mobChange = (e) => {
    //     const value = e.target.value
    //     setMobile(value);
    // }

    const otpChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '')
        setOtp(value);
    }
    const auth = getAuth();

    // Auth.auth().settings?.isAppVerificationDisabledForTesting = true
    const generateRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {

            },
        }, authentication)
    }

    const onSubmit = () => {
        setLoader(true)
        generateRecaptcha()
        let appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(authentication, mobile, appVerifier)
            .then(confirmationResult => {
                window.confirmationResult = confirmationResult;
                setLoader(false)
                setModal(true)
                setOpen(true)
                setErrormessage('OTP has been sent to your mobile number')
            }).catch((error) => {
                // alert('Invalid Mobile number') 
                console.error(error)
                setLoader(false)
            })
    }

    const otpSubmit = () => {
        setLoader(true)

        let confirmationResult = window.confirmationResult;
        confirmationResult.confirm(otp).then(result => {
            const user = result.user;
            // console.log('user', user)
            // alert('successfully registered')
            setLoader(false)
            setOpen(true)
            setErrormessage('Successfully registered')
            // otpregister(user.phoneNumber)
        }).catch((error) => {
            console.log(error)
            setLoader(false)
            alert('invalid varification code')
        })
    }

    const otpregister = (mobile) => {
        var data = {
            "mobno": mobile,
        }
        console.log("data", data)
        fetch(global.url + "auth/Mobotp", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response otp", responseJson)
                if (responseJson.error == false) {
                    var data = responseJson.data
                    var data1 = data.user_data
                    localStorage.setItem("isAuthenticatedfront", true);
                    localStorage.setItem("token", JSON.stringify(data.api_token));
                    localStorage.setItem("userName", JSON.stringify(data1.user_name));
                    localStorage.setItem("userdata", JSON.stringify(data1));
                    history.push('/');
                    cartItems()
              
                    // setModal(false)
                    // window.location.reload()
                   
                    
                    
                } else {
                    localStorage.setItem("isAuthenticatedfront", false);
                    setOpen(true)
                    setErrormessage(responseJson.message)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    var abc = localStorage.getItem("token")
    console.log(abc,"123123")



    const cartItems = () => {
        console.log('api calling 1234')
        var token = JSON.parse(localStorage.getItem("token"))
        // console.log(token,"123123")
        var data = {
            'data': cart,
        }
        console.log("data1233", data)
        fetch(global.url + "frontEndaddCart", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("responseJson11221", responseJson)
                  refresh("")
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }



    const handleGuestLogin = () => {
        console.log("handleGuestLogin function called");
        const mobileNumber = countryCode+mobile.replace(/\D/g, '');
        console.log("Mobile Number log:", mobileNumber);
        if (mobileNumber.length < 11) {
            alert("Please provide an 8-digit mobile number");
            return;
        } else {
            otpregister(mobileNumber);
        }

    };
    const handleMobileChange = (e) => {
        console.log("New Mobile Number:", e.target.value);
        const input = e.target.value;
        const formattedInput = input.replace(/\D/g, '');
        setMobile(formattedInput);
    };

    return (
        <div className="App" style={{ fontFamily: "'lato', sans-serif" }}>
            <Container fluid>
                <Row>
                    <Col xs={12} style={{ marginTop: 100 }}>
                        <div className="login-box">
                            <div className="box-inside">
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 10 }}>
                                    <div className='login-company-logo'
                                    // alt="img" style={{ height: '30%', width: '30%' }} src={logo} 
                                    ></div>
                                </div>
                                <p className='loginpara' style={{ fontSize: 18 }}>Guest Login</p>
                                <div style={{ margin: '30px 20px 0px 20px',display:'flex',flexDirection:'row' ,border:'1.5px solid #c6cbcd' ,padding:'5px 10px' ,borderRadius:'5px'}}>
                                    {/* <Form>
                                        <Form.Group className="mb-1" controlId="formBasicEmail">
                                            <Form.Control
                                                type="text"
                                                className='shadow12'
                                                value={mobile}
                                                // onChange={mobChange} 
                                                onChange={handleMobileChange}
                                                placeholder='Enter Your Mobile Number'
                                                style={{ fontSize: 14 }} />
                                        </Form.Group>
                                    </Form> */}
                                    <div>{countryCode}</div>
                                    <input type="text" className='shadow12' value={mobile} onChange={handleMobileChange} style={{ fontSize: 14 ,width:'80%' ,border:'none',outline:'none',paddingLeft:'10px'}} placeholder='Enter Your Mobile Number' />
                                </div>
                                <div style={{ marginTop: 50 }}>
                                    {loader ?
                                        <Loader /> :
                                        // <Button onClick={onSubmit} variant="primary" style={{boxShadow: 'none', border: 'none', backgroundColor: '#00a085', width: '100%',fontSize:14}}>Send OTP</Button>
                                        <Button variant="primary" onClick={handleGuestLogin} className='guestlogin-head'>GUEST LOGIN</Button>

                                    }
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <a onClick={() => history.push('/')}><p className='guestlogin-home'>Home</p></a>
                                </div>
                                <div id="recaptcha-container"></div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal
                size="md"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ padding: 25 }}>
                        <div style={{ marginTop: -15 }} className="box-inside">
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 10 }}>
                                <div  className='login-company-logo'
                                // alt="img" style={{ height: '25%', width: '25%' }} src={logo}
                                 ></div>
                            </div>
                            <p className='loginpara' style={{ fontSize: 20 }}>OTP</p>
                            <div style={{ marginTop: 30 }}>
                                <Form>
                                    <Form.Group className="mb-1" controlId="formBasicEmail">
                                        <Form.Control
                                            type="text"
                                            className='shadow12'
                                            value={otp}
                                            onChange={otpChange}
                                            placeholder='Enter OTP Number'
                                            style={{ fontSize: 13 }} />
                                    </Form.Group>
                                </Form>
                            </div>
                            <div style={{ marginTop: 50 }}>
                                {loader ?
                                    <Loader /> :
                                    <Button onClick={otpregister} variant="primary" className='guestlogin-submit'>Submit</Button>
                                }
                            </div>
                            <div id="recaptcha-container"></div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        cart: state.shop.cart,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        refresh: (item) => dispatch(refresh(item))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Guest);