import './NewArrival.css';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import Slider from "react-slick";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from 'react-router-dom';
import {
  addToCart, adjustItemQty,
  refresh
} from "../../redux/Shopping/shopping-actions";
import { connect } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import img from '../../Assets/Images/noimages.png';
import { LiaCartArrowDownSolid } from "react-icons/lia";
import { IoMdHeartEmpty } from "react-icons/io";

import { GoHeartFill } from "react-icons/go";
import { GoHeart } from "react-icons/go";
import MuiAlert from '@mui/material/Alert';
import { FaShoppingBag } from "react-icons/fa";

import bag from '../../Assets/Images/bag-2 (1).svg'

const Alert = React.forwardRef(function Alert(props, ref) {
  console.log(ref, "ref1")
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const data = [{}, {}, {}, {}, {}, {}, {}, {}]

function Products({ product, addToCart, cart, refresh, adjustQty }) {
  console.log(addToCart, "addtocart1")
  console.log(cart, "cart 1")
  console.log(refresh, "refresh 2")
  console.log(product, "prd 121212121212");
  let history = useHistory();
  // let key1 = 2;
  let key1 = localStorage.getItem("apiid");
  const [open, setOpen] = React.useState(false);
  const [logtoken, setLogtoken] = useState('');
  const [message, setMessage] = useState('');
  const [cartView, setCartview] = React.useState(false)
  const [cartCount, setCartCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [cartList, setCartList] = useState([])
  const [comboCartList, setComboCartList] = useState([]);
  const [details, setDetails] = useState({});
  const [cartQty, setCartQty] = useState('');
  const [cartTotal, setCartTotal] = useState('');
  const [isFilled, setIsFilled] = useState(false);
  const [wishlistData, setWishListData] = useState([]);

  const [productTable, setproductTable] = useState([{ id: { product_fetchiscombo: 0, product_name: '', size: [{ size_name: '', pb_id: '' }], currency: '', pb_salesrate: '' }, qty: 1 }])
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    // autoplay: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // const toClick = (product) => {
  //   console.log("product", product)
  //   addToCart(product)
  //   setOpen(true)
  //   viewCart()
  // }

  const closeviewCart = () => {
    setCartview(false)
  }

  const contHome = () => {
    history.push('/')
    setCartview(false)
  }

  useEffect(() => {
    let count = 0;
    var total = 0;
    cart.forEach((item) => {
      count += item.qty;
      total += item.qty * item.id.pb_salesrate;
    })
    setCartCount(count);
    setTotal(total);
    console.log('cart', cart)

  }, [cart, cartCount.toExponential]);

  // const AddCart = (id) => {
  //   console.log("id", id)
  //   var token = JSON.parse(localStorage.getItem("token"))
  //   var data = {
  //     "productid": id,
  //     "quantity": 1,
  //     "iscombo": 0

  //   }
  //   console.log("dataarr", data)
  //   fetch(global.url + "addCartfrontend", {
  //     method: "POST",
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'APPKEY': 'erp6129',
  //       'Authorization': 'Bearer ' + token
  //     },
  //     body: JSON.stringify(data)
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       viewCart()
  //       getCartData()
  //       if (responseJson.error == false) {
  //         setOpen(true)
  //         setMessage(responseJson.message)
  //         refresh("")
  //       }
  //       else {
  //         setOpen(true)
  //         setMessage(responseJson.message)
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }

  //   const getCartData = () => {
  //     var token = JSON.parse(localStorage.getItem("token"))
  //     fetch(global.url + "displayCartfrontend", {
  //         method: "POST",
  //          headers: {
  //              'Content-Type': 'application/json',
  //              'APPKEY': 'erp6129',
  //              'Authorization' : 'Bearer ' + token
  //          },
  //     })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log("responseJson cart123", responseJson);
  //       if(responseJson.error == false){
  //         setCartList(responseJson.singleCart);
  //         console.log(responseJson.singleCart,"single cart");
  //         setComboCartList(responseJson.ComboCart);
  //         console.log(responseJson.ComboCart,"combo cart");
  //         setCartQty(responseJson.cart_count);
  //         setCartTotal(responseJson.total);
  //       } else {
  //         setCartList([]);
  //         setComboCartList([])
  //         setCartQty(0);
  //         setCartTotal(0);
  //       }
  //     })
  //     .catch((error)=>{
  //         console.error(error)
  //     })
  // }

  const getCartData = async () => {
    try {
      // Retrieve token from localStorage
      var token = JSON.parse(localStorage.getItem("token"));

      // Make a POST request to fetch cart data
      const response = await fetch(global.url + "displayCartfrontend", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'APPKEY': 'erp6129',
          'Authorization': 'Bearer ' + token
        },
      });

      // Parse response as JSON
      const responseJson = await response.json();

      console.log("responseJson cart123", responseJson);

      // If there is no error in the response, update cart data
      if (responseJson.error == false) {
        setCartList(responseJson.singleCart);
        console.log(responseJson.singleCart, "single cart");
        setComboCartList(responseJson.ComboCart);
        console.log(responseJson.ComboCart, "combo cart");
        setCartQty(responseJson.cart_count);
        setCartTotal(responseJson.total);
      } else {
        // If there is an error, reset cart-related states
        setCartList([]);
        setComboCartList([]);
        setCartQty(0);
        setCartTotal(0);
      }
    } catch (error) {
      console.error(error); // Log any errors that occur during the request
    }
  }

  const AddCart = (e,batch1) => {
    e.stopPropagation()
    var token = JSON.parse(localStorage.getItem("token"))
    var data = {
      "productid": batch1,
      "quantity": 1,
      "iscombo": 0
    }
    fetch(global.url + "addCartfrontend", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'APPKEY': 'erp6129',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error == false) {
          console.log(responseJson, "rsp addcart")
          setOpen(true)
          setMessage("Added To Cart")
          refresh("")
          viewCart()
          getCartData()
        } else {
          refresh("")
          setOpen(true)
          setMessage(responseJson.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const toClick = (e, batchids, prd) => {
    e.stopPropagation()
    console.log(prd, "add to cart called by toclick function")
    const newPrd = {
      data:
      {
        details: {
          name: prd.name,
          products_id: prd.productid,
          // products_agcode: PR96,
          // product_userid: ,
          product_branchid: 1,
          product_companyid: 1,
          product_currencyqr: null,
          product_currencydollar: null,
          product_supplier_type: null,
          product_supplier_fk: null,
          product_code: prd.productcode,
          product_name: prd.name,
          // product_printname: BAKLAWA,
          // product_othername: \u0628\u0642\u0644\u0627\u0648\u0629,
          // product_warranty: null,
          product_refcode: prd.refcode,
          product_hsn: prd.pb_hsn,
          // product_tax: TAX,
          // product_type: 1,
          product_deliverytime: prd.deliverytime,
          product_description: prd.product_description,
          // product_itemgroup: 0,
          // product_brand: 0,
          // product_singlecombo: 0,
          // product_category: 7,
          product_subcategory: prd.subCategory_id,
          product_baseunit: prd.pb_unit,
          // product_purchaseunit: 4,
          // product_saleunit: 4,
          // product_defaultprice: 0,
          // product_branches: 1,
          product_iscombo: 0,
          product_fetchproducts: null,
          product_fetchiscombo: 0,
          product_comboprice: null,
          product_comboimage: null,
          // product_godown: 2,
          // product_segment: 1,
          // product_subsegment: 1,
          // product_displaylocation: 41,
          // product_orderlevel: 0,
          // product_orderquantity: 0,
          product_codetype: null,
          product_isblocked: 0,
          product_countryorigin: null,
          product_combostock: 0,
          product_created_at: prd.product_created_at,
          // product_updated_at: ,
          // product_sequence: 96,
          // product_inter_barcode: null,
          // product_gst: 0, 
          // product_isactive: 0,
          color_name: prd.color_name,
          // color_id: 1,
          // pei_image_fk: 213,
          pb_id: prd.pb_id,
          pb_userid: prd.pb_userid,
          pb_batch: prd.pb_batch,
          pb_unit: prd.pb_unit,
          pb_hsn: prd.pb_hsn,
          pb_tax: prd.pb_tax,
          pb_currencyqr: prd.pb_currencyqr,
          pb_currencydollar: prd.pb_currencydollar,
          pb_purchaseprice: prd.pb_purchaseprice,
          pb_purchaseorder_price: prd.pb_purchaseorder_price,
          pb_ispurchaseorder: prd.pb_ispurchaseorder,
          pb_ispurchaseorder_display: prd.pb_ispurchaseorder_display,
          pb_purchaseorderqty: prd.pb_purchaseorderqty,
          pb_salesrate: prd.pb_salesrate,
          pb_mrp: prd.pb_mrp,
          pb_baseqty: prd.pb_baseqty,
          pb_stockgroup: prd.pb_stockgroup,
          pb_stock: prd.pb_stock,
          pb_excelstock: prd.pb_excelstock,
          pb_opening: prd.pb_opening,
          pb_in: prd.pb_in,
          pb_out: prd.pb_out,
          pb_productid_fk: prd.pb_productid_fk,
          pb_expiry_date: prd.pb_expiry_date,
          pb_color_fk: prd.pb_color_fk,
          pb_size_fk: prd.pb_size_fk,
          pb_image: prd.pb_image,
          pb_uom: prd.pb_uom,
          pb_b2bprice: prd.pb_b2bprice,
          pb_onlineprice: prd.pb_onlineprice,
          pb_inter_barcode: prd.pb_inter_barcode,
          pb_isblocked: prd.pb_isblocked,
          pb_manual_barcode: prd.pb_manual_barcode,
          pb_qrcode: prd.pb_qrcode,
          pb_startdate: prd.pb_startdate,
          pb_endate: prd.pb_endate,
          pb_iseditprice: prd.pb_iseditprice,
          pb_isconvertunit: prd.pb_isconvertunit,
          pb_displayconvertunit: prd.pb_displayconvertunit,
          pb_isactive: prd.pb_isactive,
          pb_created_at: prd.pb_created_at,
          pb_updated_at: prd.pb_updated_at,
          images: [
            prd.images.map((item) => (
              {
                pei_image: item.pei_image
              }
            ))
          ],
          wishlist: prd.wishlist,
          image: [
            prd.images.map((img) => (
              {
                original: global.images + key1 + "/ecommerce_photos/" + img.pei_image,
                thumbnail: global.images + key1 + "/ecommerce_photos/" + img.pei_image
              }
            ))


          ],
          currency: prd.currency,
          batchid: prd.pb_id,
          size: [
            {
              size_name: prd.size_name,
              pb_id: prd.pb_id
            }
          ]
        },
        colors: [
          {
            name: prd.name,
            products_id: prd.productid,
            // products_agcode: PR96,
            // product_userid: 1,
            product_branchid: 1,
            product_companyid: 1,
            product_currencyqr: null,
            product_currencydollar: null,
            product_supplier_type: null,
            product_supplier_fk: null,
            product_code: prd.productcode,
            product_name: prd.name,
            // product_printname: BAKLAWA,
            // product_othername: \u0628\u0642\u0644\u0627\u0648\u0629,
            // product_warranty: null,
            product_refcode: prd.refcode,
            product_hsn: prd.pb_hsn,
            // product_tax: TAX,
            // product_type: 1,
            product_deliverytime: prd.deliverytime,
            product_description: prd.product_description,
            // product_itemgroup: 0,
            // product_brand: 0,
            // product_singlecombo: 0,
            // product_category: 7,
            product_subcategory: prd.subCategory_id,
            product_baseunit: prd.pb_unit,
            // product_purchaseunit: 4,
            // product_saleunit: 4,
            // product_defaultprice: 0,
            // product_branches: 1,
            product_iscombo: 0,
            product_fetchproducts: null,
            product_fetchiscombo: 0,
            product_comboprice: null,
            product_comboimage: null,
            // product_godown: 2,
            // product_segment: 1,
            // product_subsegment: 1,
            // product_displaylocation: 41,
            // product_orderlevel: 0,
            // product_orderquantity: 0,
            product_codetype: null,
            product_isblocked: 0,
            product_countryorigin: null,
            product_combostock: 0,
            product_created_at: prd.product_created_at,
            // product_updated_at: 2023-12-21 15:24:30,
            // product_sequence: 96,
            // product_inter_barcode: null,
            // product_gst: 0,
            // product_isactive: 0,
            color_name: prd.color_name,
            // color_id: 1,
            // pei_image_fk: 213,
            pb_id: prd.pb_id,
            pb_userid: prd.pb_userid,
            pb_batch: prd.pb_batch,
            pb_unit: prd.pb_unit,
            pb_hsn: prd.pb_hsn,
            pb_tax: prd.pb_tax,
            pb_currencyqr: prd.pb_currencyqr,
            pb_currencydollar: prd.pb_currencydollar,
            pb_purchaseprice: prd.pb_purchaseprice,
            pb_purchaseorder_price: prd.pb_purchaseorder_price,
            pb_ispurchaseorder: prd.pb_ispurchaseorder,
            pb_ispurchaseorder_display: prd.pb_ispurchaseorder_display,
            pb_purchaseorderqty: prd.pb_purchaseorderqty,
            pb_salesrate: prd.pb_salesrate,
            pb_mrp: prd.pb_mrp,
            pb_baseqty: prd.pb_baseqty,
            pb_stockgroup: prd.pb_stockgroup,
            pb_stock: prd.pb_stock,
            pb_excelstock: prd.pb_excelstock,
            pb_opening: prd.pb_opening,
            pb_in: prd.pb_in,
            pb_out: prd.pb_out,
            pb_productid_fk: prd.pb_productid_fk,
            pb_expiry_date: prd.pb_expiry_date,
            pb_color_fk: prd.pb_color_fk,
            pb_size_fk: prd.pb_size_fk,
            pb_image: prd.pb_image,
            pb_uom: prd.pb_uom,
            pb_b2bprice: prd.pb_b2bprice,
            pb_onlineprice: prd.pb_onlineprice,
            pb_inter_barcode: prd.pb_inter_barcode,
            pb_isblocked: prd.pb_isblocked,
            pb_manual_barcode: prd.pb_manual_barcode,
            pb_qrcode: prd.pb_qrcode,
            pb_startdate: prd.pb_startdate,
            pb_endate: prd.pb_endate,
            pb_iseditprice: prd.pb_iseditprice,
            pb_isconvertunit: prd.pb_isconvertunit,
            pb_displayconvertunit: prd.pb_displayconvertunit,
            pb_isactive: prd.pb_isactive,
            pb_created_at: prd.pb_created_at,
            pb_updated_at: prd.pb_updated_at,
            images: [
              prd.images.map((item) => (
                {
                  pei_image: item.pei_image
                }
              ))
            ],
            image: [
              prd.images.map((img) => (
                {
                  original: global.images + key1 + "/ecommerce_photos/" + img.pei_image,
                  thumbnail: global.images + key1 + "/ecommerce_photos/" + img.pei_image
                }
              ))
            ],
            currency: prd.currency,
            batchid: prd.pb_id
          }
        ]
      }
    }

    console.log(newPrd, "new prd array")
    adjustQty(batchids, 1);
    setMessage("Added to Cart")
    setOpen(true)
    viewCart()
  }

  // const getData = (prodctId1, batch1) => {

  //   if (logtoken == true) {
  //     console.log("add to cart called through api")
  //     var user = JSON.parse(localStorage.getItem("userdata"))
  //     console.log('user', user)
  //     var data = {
  //       'product_id': prodctId1,
  //       'batch_id': batch1,
  //       'user_id': user.user_id
  //     }
  //     console.log("getData", data)
  //     fetch(global.url + "viewProductLatest", {
  //       method: "POST",
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'APPKEY': 'erp6129'
  //       },
  //       body: JSON.stringify(data)
  //     }).then((response) => response.json())
  //       .then((responseJson) => {
  //         console.log('productdetails 121', responseJson)
  //         if (responseJson.error == false) {
  //           AddCart(batch1)
  //           setDetails(responseJson.data.details)
  //           console.log(responseJson.data.details, "r d if")
  //         } else {
  //           setDetails([])
  //         }

  //       })
  //       .catch((error) => {
  //         console.log(error, "errror");
  //       })
  //   } else {
  //     var data = {
  //       'product_id': prodctId1,
  //       'batch_id': batch1,
  //     }
  //     console.log("getData", data)
  //     fetch(global.url + "viewProductLatest", {
  //       method: "POST",
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'APPKEY': 'erp6129'
  //       },
  //       body: JSON.stringify(data)
  //     }).then((response) => response.json())
  //       .then((responseJson) => {
  //         console.log('productdetails 123', responseJson)
  //         if (responseJson.error == false) {
  //           toClick(responseJson.data.details, batch1)
  //           setDetails(responseJson.data.details)
  //           console.log(responseJson.data.details, "r d else")
  //         } else {
  //           setDetails([])
  //         }

  //       })
  //       .catch((error) => {
  //         console.log(error)
  //       })
  //   }

  // }




  useEffect(() => {
    var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"))
    setLogtoken(logged)
    getCartData()
  }, [])

  useEffect(() => {
    setMessage("")
  }, [cart])

  const viewCart = () => {
    setCartview(true)
  }

  const [clickValue, setClickValue] = useState(false)
  const [currentProduct, setCurrentProduct] = useState(product);

  const handleIconClick = (id, isFilled) => {
    wishlistClick(id, isFilled);
    setIsFilled(!isFilled);
    setClickValue(true)
    const updatedProduct = {
      ...currentProduct,
      wishlist: !currentProduct.wishlist
    };

    setCurrentProduct(updatedProduct);
  };
  // const clickArray = product.wishlist == true ? false: true;

  // const productArray = !clickValue ? product.wishlist : clickArray;

  // console.log(productArray,"product array 1223")

  const wishlistClick = (id, isFilled) => {
    console.log("wishlist clicking")
    var token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      setOpen(true)
      setMessage("please login to add to your wishlist")
      setTimeout(() => {
        history.push('/login')
      }, 2000);

      return

    }
    var data = {
      batch_id: id,
      is_combo: 0,
      mode: isFilled ? 'delete' : 'add',
    };

    fetch(global.url + "AddtowishlistOrDelete", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'APPKEY': 'erp6129',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          console.log(responseJson, "add to wishlist")
        } else {
          console.log(responseJson, "not in wish list")
        }
        console.log("order cancel response", responseJson);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(product, "product112121212")

  const getWishList = () => {

    var token = JSON.parse(localStorage.getItem("token"))
    fetch(global.url + "viewMyWishLists", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'APPKEY': 'erp6129',
        'Authorization': 'Bearer ' + token
      },

    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log('wishlist3654156', responseJson)

        if (responseJson.error == false) {
          // setWishlist(responseJson.data);  
          console.log(responseJson.data, "sdfsdfsdfsdf");
          setWishListData(responseJson.data)
        }
        else {
          // setWishlist([])
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }
  useEffect(() => {
    // getWishList()
  }, [])
  const handleNavigation = () => {
    history.push('/details/' + product.productid + '/' + product.batchid);
  };

  const handleIconClickWrapper = (e, batchid, isWishlist) => {
    e.stopPropagation();
    handleIconClick(batchid, isWishlist);
  };
  return (
    <div className='newarrivaldiv' style={{ fontFamily: "'lato', sans-serif" }}>
      <div className="main-image-container">

        <a onClick={() => handleNavigation()}>
          <Slider {...settings}>
            {product.images.length === 0 ? (
              <div>
                <img alt="img" src={img} className="imagehome" />
                {currentProduct.wishlist == true ? (
                    <GoHeartFill
                    className='activehearticon'
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={(e) => handleIconClickWrapper(e, product.batchid, true)}
                    />
                  ) : (
                    
                    <GoHeart
                    className='hearticon'
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => handleIconClickWrapper(e, product.batchid, false)}
                    />
                  )}
              </div>
            ) : (
              product.images.map((productImage) => (
                <div key={productImage.id}>
                  <img
                    alt="img"
                    src={global.images + key1 + "/ecommerce_photos/" + productImage.pei_image}
                    className="imagehome"
                  />
                  {currentProduct.wishlist == true ? (
                    <GoHeartFill
                    className='activehearticon'
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={(e) => handleIconClickWrapper(e, product.batchid, true)}
                    />
                  ) : (
                    
                    <GoHeart
                    className='hearticon'
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => handleIconClickWrapper(e, product.batchid, false)}
                    />
                  )}
                </div>
              ))
            )}
          </Slider>

          <div style={{ marginTop: 10, position: 'relative' }}>
            <p className='product-name' style={{ fontSize: 14, fontFamily: "'lato', sans-serif", fontWeight: 600 }}>{product.name}</p>
            <Row >
              <Col><p className='price' style={{ marginBottom: 'unset' }}>{product.currency}  {product.pb_salesrate} </p></Col>
              {/* {
                logtoken ?
                  <Col>  <LiaCartArrowDownSolid style={{ height: '20px', width: '20px', cursor: 'pointer' }} onClick={() => AddCart(product.batchid)} /></Col>
                  :
                  <Col>  <LiaCartArrowDownSolid style={{ height: '20px', width: '20px', cursor: 'pointer' }} onClick={(e) => toClick(e, product.batchid, product)} /></Col>
              } */}
              {/* <Col>  <LiaCartArrowDownSolid style={{ height: '20px', width: '20px', cursor: 'pointer' }} onClick={() => getData(product.productid, product.batchid)} /></Col> */}
              {/* <Col style={{ display: 'flex', justifyContent: 'end', fontSize: '20px' }}>
                {currentProduct.wishlist == true ? (
                  <IoMdHeartEmpty
                    style={{ cursor: 'pointer', color: 'red' }}
                    onClick={(e) => handleIconClickWrapper(e, product.batchid, true)}
                  />
                ) : (
                  <IoMdHeartEmpty
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => handleIconClickWrapper(e, product.batchid, false)}
                  />
                )}
              </Col> */}
              {
                logtoken ?
                  <Col style={{ display: 'flex', justifyContent: 'end', fontSize: '20px' }}>  <FaShoppingBag style={{ height: '20px', width: '20px', cursor: 'pointer' }} onClick={(e) => AddCart(e,product.batchid)} /></Col>
                  :
                  // 
                  null
              }
            </Row>

            {/* <p style={{fontSize: 12}}><span style={{textDecorationLine: 'line-through', color: '#5c5c5c'}}>{product.currency} {product.pb_salesrate}</span></p> */}
            {/* <div style={{marginTop: 25}}>
                        {logtoken ?
                          <Button onClick={() => AddCart(product.pb_id)} variant="warning home-cartbtn" className="btn-warning">Add To Cart</Button>
                          :<Button onClick={() => toClick(product)} variant="warning home-cartbtn" className="btn-warning">Add To Cart</Button>}
                      </div> */}

          </div>
        </a>
      </div>
      {cartView ?
        <div className="cart-content">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <p style={{ fontSize: 16, marginTop: 1, fontFamily: "'lato', sans-serif", fontWeight: 600 }}>Cart</p>
            </div>
            <a onClick={closeviewCart}>
              <AiOutlineClose color="#000" size={18} />
            </a>
          </div>
          {logtoken ?
            <div className='innerdiv' style={{ fontFamily: "'lato', sans-serif" }}>
              {cartList.length === 0 && comboCartList.length === 0 ? <p style={{ fontSize: 14, fontWeight: 400, textAlign: 'center', marginTop: 30 }}>No Cart Added</p> :
                <div>
                  <div>
                    {cartList.map((item, index) => (
                      <div>
                        <Row>
                          <Col xs={3}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              {
                                item.image == null ?
                                  <img alt="img" src={img} style={{ width: 80, height: 80 }} /> :
                                  <img alt="img" src={global.images + key1 + "/ecommerce_photos/" + item.image} style={{ width: 80, height: 80 }} />
                              }
                            </div>
                          </Col>
                          <Col xs={9}>
                            <div>
                              <p className='product-name' style={{ fontSize: 11, fontFamily: "'lato', sans-serif", fontWeight: 500 }}>{item.name}</p>
                              <p style={{ fontSize: 11, fontWeight: 500, color: '#000', marginBottom: 'unset', }}>{item.quantity} x {item.price} {item.currency}</p>
                            </div>
                          </Col>
                        </Row>
                        <div style={{ backgroundColor: '#d3d3d3', width: '100%', height: 1 }}></div>
                      </div>
                    ))}
                    {comboCartList.map((item, index) => (
                      <div>
                        <Row>
                          <Col xs={3}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              {
                                item.image == null ?
                                  <img alt="img" src={img} style={{ width: 80, height: 80 }} /> :
                                  <img alt="img" src={global.images + key1 + "/combo_photos/" + item.image} style={{ width: 80, height: 80 }} />
                              }
                            </div>
                          </Col>
                          <Col xs={9}>
                            <div>
                              <p className='product-name' style={{ fontSize: 11 }}>{item.name}</p>
                              {/* <p style={{fontSize: 11, color: '#000', marginBottom: 'unset', fontWeight: 'bold'}}>{item.quantity} x {item.price} {item.currency}</p> */}
                            </div>
                          </Col>
                        </Row>
                        <div style={{ backgroundColor: '#d3d3d3', width: '100%', height: 1 }}></div>
                      </div>
                    ))}
                  </div>
                </div>
              }
            </div> :
            <div className='innerdiv' style={{ fontFamily: "'lato', sans-serif" }}>
              {cart.length == 0 ? <p style={{ fontSize: 14, fontWeight: 500, textAlign: 'center', marginTop: 30 }}>No Cart Added</p> :
                <div>
                  <div>
                    {cart.map((item, index) => (
                      <div>
                        <Row>
                          <Col xs={3}>
                            <div style={{ justifyContent: 'center' }}>
                              {
                                item.id.images.length == 0 ?
                                  <img alt="img" src={img} style={{ width: 80, height: 80 }} /> :
                                  <img alt="img" src={global.images + key1 + "/ecommerce_photos/" + item.id.images[0].pei_image} style={{ width: 80, height: 80 }} />
                              }
                            </div>
                          </Col>
                          <Col xs={9}>
                            <div style={{ fontWeight: 500, fontFamily: "'lato', sans-serif" }}>
                              <p className='product-name' style={{ fontSize: 11, fontWeight: 500 }}>{item.id.name}</p>
                              <p style={{ fontSize: 12, color: '#000', marginBottom: 'unset', fontWeight: 500 }}>{item.qty} x {item.id.pb_salesrate} {item.id.currency}</p>
                            </div>
                          </Col>
                        </Row>
                        <div style={{ backgroundColor: '#d3d3d3', width: '100%', height: 1 }}></div>
                      </div>
                    ))}
                  </div>

                </div>
              }
            </div>
          }
          {logtoken ?
            <div style={{ marginTop: 20 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p style={{ fontWeight: 500, fontSize: 14, fontFamily: "'lato', sans-serif", fontWeight: 400 }}>Sub total</p>
                <p style={{ fontWeight: 500, fontSize: 14, fontFamily: "'lato', sans-serif", fontWeight: 400 }}>QAR {cartTotal}</p>
              </div>
              <Row>
                <Col xs={6}>
                  <Button onClick={() => history.push('/checkout')} variant="primary" className='checkoutbtn'
                  //  style={{ width: '100%', borderColor: '#00a085', backgroundColor: '#00a085', color: '#fff', marginTop: 10, boxShadow: 'none', width: '100%',fontSize: 13 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}
                  >Checkout</Button>
                </Col>
                <Col xs={6}>
                  <Button onClick={() => contHome()} variant="light" className='continueshoppingbtn'
                  // style={{ width: '100%', borderColor: '#00a085', backgroundColor: '#00a085', color: '#fff', marginTop: 10, boxShadow: 'none', width: '100%',fontSize: 13 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}
                  >Continue Shopping</Button>
                </Col>
              </Row>
            </div> :
            <div style={{ marginTop: 20 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p style={{ fontSize: 14, fontFamily: "'lato', sans-serif", fontWeight: 400 }}>Sub total</p>
                <p style={{ fontSize: 14, fontFamily: "'lato', sans-serif", fontWeight: 400 }}>QAR {total}</p>
              </div>
              <Row>
                <Col xs={12} md={6}>
                  <Button onClick={() => history.push('/login')} variant="primary" className='checkoutbtn'
                  // style={{ width: '100%', borderColor: '#00a085', backgroundColor: '#00a085', color: '#fff', marginTop: 10, boxShadow: 'none', width: '100%',fontSize: 13 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}
                  >Checkout</Button>
                </Col>
                <Col xs={12} md={6}>
                  <Button onClick={() => contHome()} variant="light" className='continueshoppingbtn'
                  // style={{ width: '100%', borderColor: '#00a085', backgroundColor: '#00a085', color: '#fff', marginTop: 10, boxShadow: 'none', width: '100%',fontSize: 13 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}
                  >Continue Shopping</Button>
                </Col>
              </Row>
            </div>
          }
        </div> : null}


      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => dispatch(addToCart(item)),
    adjustQty: (id, value) => dispatch(adjustItemQty(id, value)),
    refresh: (item) => dispatch(refresh(item))
  };
};

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);