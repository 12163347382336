import React, { useState, useEffect } from "react";
import "./header.css";
import { Container, Row, Col, Form } from "react-bootstrap";
import { FaMobileAlt } from "react-icons/fa";
import { FiSearch, FiLogIn } from "react-icons/fi";
import Loader from "../../Components/Loader/Loader";
import {
  FaUserAlt,
  FaListAlt,
  FaLessThanEqual,
  FaGalacticSenate,
} from "react-icons/fa";

import { AiFillHeart } from "react-icons/ai";
import { MdDeliveryDining, MdLogout } from "react-icons/md";

import { RiCustomerService2Fill } from "react-icons/ri";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import { BsArrowReturnRight } from "react-icons/bs";
import logo from "../../Assets/Images/snap qatar logo  1.png";
import cart from "../../Assets/Images/cart.svg";
import Searchic from "../../Assets/Images/searchic.svg";
import { useHistory, useLocation } from "react-router-dom";
import profile from "../../Assets/Images/user.svg";
import Carticocomponent from "./Carticocomponent";
import Categorymenu from "./categorymenu";
import vector from "../../Assets/Images/Vector 6.svg";
import delivery from "../../Assets/Images/Group 232.svg";
import customer from "../../Assets/Images/Group 250 1 (1).svg";
import mobilemenu from "../../Assets/Images/Group 268.svg";
import mobileuser from "../../Assets/Images/user-circle.svg";
import { GoHeart } from "react-icons/go";
import { IoMdMenu } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { CiSearch } from "react-icons/ci";

function Header(props) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [userData, setUserData] = useState();
  const [logtoken, setLogtoken] = useState("");
  const [username, setUsername] = useState("");
  const [hidden, setHidden] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const [mobsearch, setMobsearch] = useState(false);

  const [catmenu, setCatmenu] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const handleSearch = (event) => {
    const searchWord = event.target.value;
    console.log("searchWord", searchWord);
    setWordEntered(searchWord);
    var data = {
      search_product: searchWord,
      limit: 15,
    };
    fetch(global.url + "searchProducts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("search response : ", responseJson);
        if (responseJson.error == false) {
          setFilteredData(responseJson.data);
        } else {
          setFilteredData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  const submenuOpen = () => {
    setCatmenu(!catmenu);
  };

  useEffect(() => {
    setCatmenu(false);
  }, [location.pathname]);

  useEffect(() => {
    var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"));
    var username = JSON.parse(localStorage.getItem("userName"));
    setLogtoken(logged);
    setUsername(username);
    console.log(username, "username");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  const handleKeyDown = (e) => {
    if (filteredData.length > 0) {
      if (e.key === 'ArrowDown') {
        setHighlightedIndex((prevIndex) =>
          prevIndex < filteredData.length - 1 ? prevIndex + 1 : prevIndex
        );
      } else if (e.key === 'ArrowUp') {
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
      } else if (e.key === 'Enter') {
        if (highlightedIndex >= 0 && highlightedIndex < filteredData.length) {
          listClick(filteredData[highlightedIndex]);
        }
      }
    }
  };


  const listClick = (value) => {
    console.log("Clicked value:", value);
    setFilteredData([]);
    setWordEntered("");
    const productId = value.products_id;
    const pbid = value.pb_id;
    history.push(`/details/${productId}/${pbid}`);
  };

  const onMobileclick = () => {
    props.parentCallback();
  };

  const hideHeader = () => {
    if (window.innerWidth < 576) {
      console.log("enters the header if condition")
      setHidden(true);
      return;
    }else{
      console.log("enters the header else")
      if (window.scrollY > lastScrollY) {
        console.log("enters the else if condition")
        setHidden(false);
      } else {
        console.log("enters the else else condition")
        setHidden(true);
      }
      if (window.scrollY > 10) {
        setLastScrollY(window.scrollY);
      }
    }
    
  };

  useEffect(() => {
    window.addEventListener("scroll", hideHeader);
    return () => {
      window.removeEventListener("scroll", hideHeader);
    };
  }, [lastScrollY]);
  const [loader, setLoader] = React.useState(false);
  const logoutClick = () => {
    setLoader(true);
    var token = JSON.parse(localStorage.getItem("token"));
    fetch(global.url + "logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoader(false);
        console.log("logout", responseJson);
        if (responseJson.error == false) {
          localStorage.setItem("isAuthenticatedfront", false);
          localStorage.removeItem("token");
          localStorage.removeItem("userName");
          localStorage.removeItem("persist:root");
          localStorage.removeItem("userdata");
          history.push("/");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const  wishlistClick=()=>{
    if(logtoken){
      history.push('/wishlists')
    }else{
      alert("please login to see your wishlist !")
      history.push('/login')
    }
  }

  return (
    <div className={hidden ? "header" : "header hidden"}>
      <div className="header1">
        <Container>
          <Row>
            <Col md={3}>
              <a style={{ display: "flex" }}>
                <FaMobileAlt
                  color="#fad30c"
                  size={16}
                  style={{ marginTop: 3, marginRight: 10 }}
                />
                <p className="header1-para">Download The App Now</p>
              </a>
            </Col>
            <Col md={3}>
              <a style={{ display: "flex" }}>
                <div
                  className="delivery"
                // src={delivery}
                // style={{
                //   marginTop: 1,
                //   marginRight: 10,
                //   width: 20,
                //   height: 20,
                // }}
                ></div>
                {/* <MdDeliveryDining color="#fad30c" size={22} style={{marginTop: 1, marginRight: 10 }}/> */}
                <p className="header1-para">
                  Free Home Delivery Starting At Above 99 QAR
                </p>
              </a>
            </Col>
            <Col md={3}>
              <button className="premium-btn">premium</button>
            </Col>

            <Col md={3}>
              <a style={{ display: "flex", float: "right" }}>
                <div
                  className="customercare"

                // src={customer}
                // style={{
                //   marginTop: 1,
                //   marginRight: 10,
                //   width: 20,
                //   height: 20,
                // }}
                ></div>
                {/* <RiCustomerService2Fill color="#fad30c" size={20} style={{marginTop: 2, marginRight: 10}}/> */}
                <p className="header1-para">24/7 Customer Care</p>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="header2">
        <Container fluid="md">
          <Row>
            <Col xs={3} md={3} lg={3} className="logocol">
              <div style={{}}>
                {/* <div>
                                    <div style={{position: 'relative', marginTop: 10, cursor: 'pointer'}} onClick={() => submenuOpen()}>
                                        <a>
                                            <HiMenuAlt1 color="#286bc7" size={28} style={{marginTop: 2, marginRight: 10}}/>
                                        </a>
                                    </div>
                                    <Categorymenu catmenu = {catmenu}/>
                                </div> */}
                <FiMenu
                  onClick={() => onMobileclick()}
                  className="desktop-display"
                  style={{
                    width: 25,
                    height: 25,
                    color: "#00a085",
                    marginRight: 8,
                    marginTop: 22,
                    cursor: "pointer",
                  }}
                />

                <a
                  // className="company-logo-div"
                  href="/"
                  style={{ cursor: "pointer" }}
                >
                  <div className="company-logo"></div>
                </a>
              </div>
            </Col>
            <Col className="searchbar" xs={5} md={5} lg={5}>
              <div style={{ position: "relative" }}>
                <div className="searchdiv">
                  {/* <Form>
                                        <Form.Group className="mb-3 mt-4" >
                                            <Form.Control type="text" onChange={handleSearch} value={wordEntered} placeholder="What are you looking for?" 
                                             style={{borderColor: '#00a085', fontWeight:500,fontSize:15, fontFamily: "'lato', sans-serif"}} />
                                        </Form.Group>
                                    </Form> */}
                  <input
                    className="mb-3 mt-3"
                    type="text"
                    onChange={handleSearch}
                    value={wordEntered}
                    onKeyDown={handleKeyDown}
                    placeholder="What are you looking for?"
                    style={{
                      borderColor: "#00a085",
                      fontWeight: 400,
                      fontSize: 17,
                      fontFamily: "'lato', sans-serif",
                      width: "100%",
                      height: "43px",
                      borderWidth: "3px",
                      borderRadius: "15px 15px 15px 0px",
                      textIndent: "10px",
                      color: "#000",
                    }}
                  ></input>
                  {filteredData.length === 0 ? (
                    <div
                      style={{
                        position: "absolute",
                        backgroundColor: "#00a085",
                        borderRadius: "0px 15px 15px 0px",
                        top: "16px",
                        right: "0px",
                        padding: "8px",
                      }}
                    >
                      <span style={{ textAlign: "center", padding: 5 }}>
                        <FiSearch color="white" size={25} />
                      </span>
                    </div>
                  ) : (
                    <AiOutlineClose
                      onClick={clearInput}
                      color="#000"
                      size={17}
                      style={{
                        position: "absolute",
                        top: 29,
                        right: 10,
                      }}
                    />
                  )}
                </div>
                {filteredData.length == 0 ? null : (
                  <div className="dataResult">
                    {filteredData.map((value, key) => {
                      return (
                        <a
                          onClick={() => listClick(value)}
                          className="searchItem"
                          target="_blank"

                        >
                          <div style={{ marginTop: 10, display: "flex" }}>
                            <BsArrowReturnRight
                              // color="#000"
                              size={14}
                              style={{ marginLeft: 20, marginTop: 2, color: key === highlightedIndex ? '#00a085' : '#000' }}
                            />
                            <p style={{ fontSize: 12, marginLeft: 10, color: key === highlightedIndex ? '#00a085' : '#000' }}>
                              {value.product_name}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              height: 1,
                              backgroundColor: key === highlightedIndex ? '#00a085' : '#d3d3d3'
                            }}
                          ></div>
                        </a>
                      );
                    })}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={4} md={4} lg={4}>
              <div
                className="responsive-cart"
                style={{
                  display: "flex",
                  float: "right",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 10,
                  }}
                >
                  {logtoken ? (
                    <div className="profileicon" style={{ position: 'relative' }}>
                      <a style={{ cursor: "pointer" }} href="/profile">
                        <CgProfile size={25} color="white" />
                      </a>
                      {/* <div className="dropdown-content">
                        <a href="/profile">
                          <p
                            style={{
                              fontSize: 14,
                              cursor: "pointer",
                              fontWeight: 500,
                              fontFamily: "'lato', sans-serif",
                            }}
                          >
                            <FaUserAlt
                              color="#000"
                              size={13}
                              style={{ marginRight: 7, marginTop: -2 }}
                            />{" "}
                            My Profile
                          </p>
                        </a>
                        <a href="/order">
                          <p
                            style={{
                              fontSize: 14,
                              cursor: "pointer",
                              fontWeight: 500,
                              fontFamily: "'lato', sans-serif",
                            }}
                          >
                            <FaListAlt
                              color="#000"
                              size={15}
                              style={{ marginRight: 5, marginTop: -2 }}
                            />{" "}
                            My Orders
                          </p>
                        </a>
                        <a href="/wishlists">
                          <p
                            style={{
                              fontSize: 14,
                              cursor: "pointer",
                              fontWeight: 500,
                              fontFamily: "'lato', sans-serif",
                            }}
                          >
                            <AiFillHeart
                              color="#000"
                              size={15}
                              style={{ marginRight: 5, marginTop: -2 }}
                            />{" "}
                            My Wishlist
                          </p>
                        </a>
                        <a >
                        
                     
                          <div onClick={logoutClick}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >  {loader ? (
                            <Loader />
                          ) : (
                            <div style={{ paddingBottom: 1 }}>
                              <p
                                style={{
                                  fontSize: 14,
                                  cursor: "pointer",
                                  fontWeight: 500,
                                  fontFamily: "'lato', sans-serif",
                                }}
                              >
                                <MdLogout
                                  color="#000"
                                  size={16}
                                  style={{
                                    marginRight: 5,
                                    marginLeft: 3,
                                    marginTop: -2,
                                  }}
                                />{" "}
                                Logout
                              </p>
                            </div>
                          )}
                           
                          </div>
                        </a>
                      </div> */}
                    </div>
                  ) : (

                    <div className="profileicon">
                      <a style={{ cursor: "pointer" }} href="/login">
                        <CgProfile size={25} color="white" />
                      </a>
                      {/* <div className="dropdown-content">
                        
                       
                        <a style={{ cursor: "pointer" }} href="/login">
                        
                     
                          <div 
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >  
                            <div style={{ paddingBottom: 1 }}>
                              <p
                                style={{
                                  fontSize: 14,
                                  cursor: "pointer",
                                  fontWeight: 500,
                                  fontFamily: "'lato', sans-serif",
                                }}
                              >
                                <MdLogout
                                  color="#000"
                                  size={16}
                                  style={{
                                    marginRight: 5,
                                    marginLeft: 3,
                                    marginTop: -2,
                                  }}
                                />{" "}
                                Login
                              </p>
                            </div>
                         
                           
                          </div>
                        </a>
                      </div> */}
                    </div>

                  )}
                  <div >
                    {logtoken ? (
                      <div className="cursorusername">
                        <a
                          style={{ cursor: 'pointer', textDecoration: 'none' }}
                          className="mobile-display"
                        >
                          <p
                            style={{
                              color: '#000',
                              fontFamily: "'lato', sans-serif",
                              fontSize: '16px',
                              fontWeight: 600,
                              textDecoration: 'none',
                            }}
                          >
                            {username}
                            <img alt="albadar" src={vector} />

                          </p>
                        </a>
                        <div className="dropdown-content1">
                          <a href="/profile">
                            <p
                              style={{
                                fontSize: 14,
                                cursor: 'pointer',
                                fontWeight: 500,
                                fontFamily: "'lato', sans-serif",
                              }}
                            >
                              <FaUserAlt
                                color="#000"
                                size={13}
                                style={{ marginRight: 7, marginTop: -2 }}
                              />{' '}
                              My Profile
                            </p>
                          </a>
                          <a href="/order">
                            <p
                              style={{
                                fontSize: 14,
                                cursor: 'pointer',
                                fontWeight: 500,
                                fontFamily: "'lato', sans-serif",
                              }}
                            >
                              <FaListAlt
                                color="#000"
                                size={15}
                                style={{ marginRight: 5, marginTop: -2 }}
                              />{' '}
                              My Orders
                            </p>
                          </a>
                          <a href="/wishlists">
                            <p
                              style={{
                                fontSize: 14,
                                cursor: 'pointer',
                                fontWeight: 500,
                                fontFamily: "'lato', sans-serif",
                              }}
                            >
                              <AiFillHeart
                                color="#000"
                                size={15}
                                style={{ marginRight: 5, marginTop: -2 }}
                              />{' '}
                              My Wishlist
                            </p>
                          </a>
                          <a onClick={() => { logoutClick() }} >
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div style={{ paddingBottom: 1 }}>
                                <p
                                  style={{
                                    fontSize: 14,
                                    cursor: 'pointer',
                                    fontWeight: 500,
                                    fontFamily: "'lato', sans-serif",
                                  }}
                                >
                                  <MdLogout
                                    color="#000"
                                    size={16}
                                    style={{ marginRight: 5, marginLeft: 3, marginTop: -2 }}
                                  />{' '}
                                  Logout
                                </p>
                              </div>

                            </div>
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div className="cursorusername1">
                        <a
                          onClick={() => history.push("/login")}
                          style={{ cursor: "pointer", textDecoration: "none" }}
                          className="mobile-display"
                        >
                          <p
                            className="textfont"
                            style={{
                              color: "#000",
                              fontFamily: "'lato', sans-serif",
                              fontSize: "16px",
                              fontWeight: 600,
                              textDecoration: "none",
                            }}
                          >
                            Sign In/Sign Up
                            <img
                              alt="albadar"
                              src={vector}
                              style={{ color: "#000" }}
                            />
                          </p>
                        </a>
                        <div className="dropdown-content2">


                          <a style={{ cursor: "pointer" }} href="/login">


                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ paddingBottom: 1 }}>
                                <p
                                  style={{
                                    fontSize: 14,
                                    cursor: "pointer",
                                    fontWeight: 500,
                                    fontFamily: "'lato', sans-serif",
                                  }}
                                >
                                  <MdLogout
                                    color="#000"
                                    size={16}
                                    style={{
                                      marginRight: 5,
                                      marginLeft: 3,
                                      marginTop: -2,
                                    }}
                                  />{" "}
                                  Login
                                </p>
                              </div>


                            </div>
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <Carticocomponent />

                {/* mobile search bar */}
                {/* <div className='desktop-display'>
                                    <a style={{cursor: 'pointer'}} onClick={() => setMobsearch(!mobsearch)}>
                                        <div>
                                            <img alt="albadar" src={Searchic} className="profileicon" />
                                        </div>
                                    </a>
                                </div> */}
              </div>
            </Col>
          </Row>
          {/* <div className={mobsearch ?  'mobsearchdiv1' : 'mobsearchdiv'}>
                            <div className='searchdiv1'>
                                <Form style={{height: '100%'}}>
                                    <Form.Group className="mb-3" style={{height: '100%'}}>
                                        <Form.Control type="text" onChange={handleSearch} value={wordEntered} placeholder="What are you looking for?" 
                                        className="header-textinput" style={{borderColor: '#00a085', height: '100%' ,fontWeight:500,fontSize:13, fontFamily: "'lato', sans-serif"}} />
                                    </Form.Group>
                                </Form>
                                
                                    <AiOutlineClose onClick={() => {setMobsearch(!mobsearch) 
                                    setFilteredData([])
                                     setWordEntered("")}} color="#000" size={17} style={{position: 'absolute', top: 16, right: 10, width:20, height:20}}/>
                            </div>
                            {filteredData.length == 0 ? null:
                                <div className="dataResult">
                                {filteredData.map((value, key) => {
                                    return (
                                    <a onClick={() => listClick(value)} className="searchItem" target="_blank">
                                        <div style={{marginTop:10, display: 'flex'}}>
                                            <BsArrowReturnRight color="#000" size={14} style={{marginLeft: 20, marginTop: 2}}/>
                                            <p style={{fontSize: 12, marginLeft: 10}}>{value.product_name}</p>
                                        </div>
                                        <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                    </a>
                                    );
                                })}
                                </div>
                            }
                        </div> */}
        </Container>
      </div>
      <div className="mobhead">
        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Col xs={1}>
            <div>
              <div
                onClick={() => onMobileclick()}
                className="menu"

              // src={mobilemenu}
              ><IoMdMenu className="homemenuicon" /></div>
            </div>
          </Col>
          <Col xs={8} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start',paddingLeft:'0px' }}>
            <a
              href="/"
              style={{ cursor: "pointer" }}
            // className="company-logo-div"
            >
              <div className="company-logo-white"></div>
            </a>
            <p style={{ marginBottom: '0px', display: 'flex', alignItems: 'center', color: '#fff', fontFamily: "'lato', sans-serif",fontSize:'22px',fontWeight:600 }}>AL BADAR</p>
          </Col>

          {/* <Col
            className="msearchbar"
            xs={5}
              lg={{ span: 5, order: 2 }}
          >
            <div style={{ position: "relative" }}>
              <div>
                <Form>
                                        <Form.Group className="mb-3 mt-4" >
                                            <Form.Control type="text" onChange={handleSearch} value={wordEntered} placeholder="What are you looking for?" 
                                             style={{borderColor: '#00a085', fontWeight:500,fontSize:15, fontFamily: "'lato', sans-serif"}} />
                                        </Form.Group>
                                    </Form>
                <input
                  className="mb-3 mt-3"
                  type="text"
                  onChange={handleSearch}
                  value={wordEntered}
                  placeholder="search"
                  style={{
                    borderColor: "#00a085",
                    fontWeight: 400,
                    fontSize: 13,
                    fontFamily: "'lato', sans-serif",
                    width: "100%",
                    height: "35px",
                    borderWidth: "1px",
                    textIndent: "10px",
                    color: "#000",
                  }}
                ></input>
                {filteredData.length === 0 ? (
                  <div
                    style={{
                      backgroundColor: "#00a085",
                      position: "absolute",
                      top: "0px",
                      right: "0px",
                      padding: "4px",
                    }}
                  >
                    {" "}
                    <span style={{ textAlign: "center", padding: 5 }}>
                      <FiSearch color="#fff" size={25} />
                    </span>{" "}
                  </div>
                ) : (
                  <AiOutlineClose
                    onClick={clearInput}
                    color="#000"
                    size={17}
                    style={{ position: "absolute", top: 29, right: 10 }}
                  />
                )}
              </div>
              {filteredData.length == 0 ? null : (
                <div className="dataResult">
                  {filteredData.map((value, key) => {
                    return (
                      <a
                        onClick={() => listClick(value)}
                        className="searchItem"
                        target="_blank"
                      >
                        <div style={{ marginTop: 10, display: "flex" }}>
                          <BsArrowReturnRight
                            color="#000"
                            size={14}
                            style={{ marginLeft: 20, marginTop: 2 }}
                          />
                          <p style={{ fontSize: 12, marginLeft: 10 }}>
                            {value.product_name}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            height: 1,
                            backgroundColor: "#d3d3d3",
                          }}
                        ></div>
                      </a>
                    );
                  })}
                </div>
              )}
            </div>
          </Col> */}
          {/* <Col xs={1}></Col> */}
          <Col xs={3}>

            <div className="homewishdiv">
              <GoHeart className="homewishicon" onClick={()=>wishlistClick()} />
            </div>
            {/* <div
              style={{
                display: "flex",
                float: "right",
                alignItems: "center",
              }}
            >
              <div 
              // onClick={() => onMobileclick()}
              >
                <span
                  style={{
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "30px",
                    width: " 30px",
                    height: "30px ",
                    marginRight: 15,

                    backgroundColor: "#00a085",
                  }}
                >
                  <FiSearch color="white" size={20} />
                </span>
              </div>
              {logtoken ? (
                <a style={{ cursor: "pointer" }} href="/profile">
                  <div>
                    <div
                      className="profileicon"
                      alt="albadar"
                    // src={mobileuser} style={{ width: '30px', height: '30px' }}
                    >
                      <CgProfile size={20} color="white" />
                    </div>

                  </div>
                </a>
              ) : (
                <a style={{ cursor: "pointer" }} href="/login">
                  <div>
                    <div
                      className="profileicon"
                    // alt="albadar" src={mobileuser} style={{ width: '30px', height: '30px' }}
                    >
                      <CgProfile size={20} color="white" />
                    </div>
                  </div>
                </a>
              )}

              <div>
                {logtoken ? (
                  <a style={{ cursor: "pointer", textDecoration: "none" }}>
                    <span
                      style={{
                        color: "#000",
                        fontFamily: "'lato', sans-serif",
                        fontSize: "10px",

                        // fontWeight: 600,
                        textDecoration: "none",
                        paddingLeft: "10px",
                        marginTop: "3px",
                      }}
                      className="textfont1"
                    >
                      {username}
                      <span><img alt="albadar" src={vector} /></span>
                    </span>
                  </a>
                ) : (
                  <a
                    onClick={() => history.push("/login")}
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    <span
                      className="textfont1"
                      style={{
                        color: "#000",
                        fontFamily: "'lato', sans-serif",
                        fontSize: "10px",
                        // fontWeight: 600,
                        textDecoration: "none",
                        paddingLeft: "10px",
                      }}
                    >
                      Sign In/Sign Up
                      <img alt="albadar" src={vector} />
                    </span>
                  </a>
                )}
              </div>
              
            </div> */}
          </Col>
        </Row>
        <Row style={{paddingTop:'6px'}}>
          <Col xs={12}>
            <div className="mobileserachinp">
              <div style={{ padding: '5px' }}>
                <input
                  type="text"
                  onChange={handleSearch}
                  value={wordEntered} placeholder="Search Here"
                  style={{ width: '90%', backgroundColor: 'transparent', border: 'none', outline: 'none', paddingLeft: '17px', fontFamily: "'lato', sans-serif", color: '#fff' }} />
                {filteredData.length === 0 ? (
                      <CiSearch style={{ height: '30px', width: '30px', color: '#45855e' }} />
                ) : (
                  <AiOutlineClose
                    onClick={clearInput}
                    color="#fff"
                    size={17}
                    style={{ height:'25px',width:'25px'}}
                  />
                )}


              </div>
            </div>

          </Col>
        </Row>
        {filteredData.length == 0 ? null : (
          <div className="dataResult">
            {filteredData.map((value, key) => {
              return (
                <a
                  onClick={() => listClick(value)}
                  className="searchItem"
                  target="_blank"
                >
                  <div style={{ marginTop: 10, display: "flex" }}>
                    <BsArrowReturnRight
                      color="#000"
                      size={14}
                      style={{ marginLeft: 20, marginTop: 2 }}
                    />
                    <p style={{ fontSize: 12, marginLeft: 10 }}>
                      {value.product_name}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      backgroundColor: "#d3d3d3",
                    }}
                  ></div>
                </a>
              );
            })}
          </div>
        )}
      </div>

    </div>
  );
}

export default Header;
