import React from "react";
import cat from "../../Assets/icons/element-4 (1).svg"
import userbottom from "../../Assets/icons/user (1).svg"
import cartbottom from "../../Assets/icons/shopping-cart (1).svg"
import home from "../../Assets/icons/home-2 (1).svg"
import { Row,Col } from "react-bootstrap";

function BottomBar() {


    return (
        <div className="bottombar" style={{ display: 'block', backgroundColor: '#0C7424' }}>
            <Row>
                <Col className="bottombar-col"><a href="/"><img className="bottombar-icons" src={home} /></a></Col>
                <Col className="bottombar-col"><a href="/categories"><img className="bottombar-icons" src={cat} /></a></Col>
                <Col className="bottombar-col"><a href="/cart"><img className="bottombar-icons" src={cartbottom} /></a></Col>
                <Col className="bottombar-col"><a href="/profile"><img className="bottombar-icons" src={userbottom} /></a></Col>
            </Row>
        </div>

    )
}
export default BottomBar