import React, { useEffect, useLayoutEffect } from "react";

function ImageDirectory() {
    // const globalValue = global.url

    useEffect(() => {
        // localStorage.removeItem("apiid");
        apiValue()
        localStorage.getItem("apiid")
        console.log(localStorage.getItem("apiid"),"api id ")

    }, [])

    async function apiValue() {

        // console.log("calling this path api")
        var imageKeyValue = JSON.parse(localStorage.getItem("apiid"))
        // console.log(imageKeyValue,"im value")

        if (imageKeyValue || imageKeyValue>0 || imageKeyValue != undefined ) {

            // console.log('calls the if part of path')
            // return localStorage.getItem("apiid");
          return localStorage.getItem("apiid");
        } else {

            // console.log('calls the else part of path')

            const response = await fetch(global.url + 'selectpath', {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'APPKEY': 'erp6129'
                },
            });
            const responseJson = await response.json();
            if (responseJson.error === false) {

                // console.log(responseJson.data.master_id,"path resp")

                var dirData = responseJson.data.master_id;
                localStorage.setItem("apiid",dirData);

                var imKeyValue = JSON.parse(localStorage.getItem("apiid"));
                 console.log(imKeyValue,"api id dasdasdasdads");
                
                return localStorage.getItem("apiid");
            } else {
                console.log(responseJson,"path resp2")
                console.error(responseJson.message);
            }
        }
    }
    
    let key = localStorage.getItem("apiid");
    console.log(key,"global key")
    return (
        <div>
        </div>
    )
}
export default ImageDirectory;